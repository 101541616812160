var seasonConfig = {
    autoplay: false,
    infinite: true,
    useIcons: true,
    editor: {
//        enable : true
    },
    steps: [
        {
            addClass: 'ca-1',
            duration: 5000
        }
    ]
};