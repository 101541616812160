var loveConfig = {
    autoplay: true,
    infinite: false,
    useIcons: true,
    steps: [
        {
            addClass: 'ca-1',
            duration: 800
        },
        {
            addClass: 'ca-2',
            duration: 800
        },
        {
            addClass: 'ca-3',
            duration: 800
        },
        {
            addClass: 'ca-4'
        }
    ]
};